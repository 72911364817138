import './App.css';
import Header from './components/Header';
import "fontsource-inter";
import Footer from './components/Footer';
import { ReactNode } from 'react';

export type AppProps = {
  element: ReactNode;
};

export default function App({ element }: AppProps) {
  return (
    <div style={{
      background: "#2B2C31",

      flex: 1
    }}>
      <Header/>
      
      <section style={{
        background: "rgb(37, 37, 42)",
        color: "rgb(200, 200, 200)",

        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <p>A beta version is out on the <a href="https://marketplace.visualstudio.com/items?itemName=norasoderlund.integrationworkbench&ssr=false#overview" target="_blank" rel="noreferrer">marketplace</a>! Help us make it better by providing <a href="https://github.com/nora-soderlund/IntegrationWorkbench/issues/new?assignees=nora-soderlund&labels=enhancement&projects=&template=feature_request.md&title=" target="_blank" rel="noreferrer">feedback on GitHub</a>.</p>
      </section>
      
      {element}

      <Footer/>
    </div>
  );
}

