import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug, faCode, faCodePullRequest, faServer } from "@fortawesome/free-solid-svg-icons";
import React, { ReactNode } from "react";
import Preview from "./Preview";

export default function Main() {
  return (
    <main style={{
    }}>
      <Preview/>

      <section style={{
        background: "rgb(34, 34, 38)",

        display: "flex",
        justifyContent: "center",

        padding: "3em 0"
      }}>
        <div className="container">
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "2em",
            textAlign: "center",
            flexWrap: "wrap"
          }}>
            <Feature icon={
                <FontAwesomeIcon icon={faCodePullRequest} style={{
                  fontSize: "3.5em"
                }}/>
              }
              label={
                <React.Fragment>
                  Fully open-source and free, <br/>
                  licensed under GNU GPL
                </React.Fragment>
              }/>

            <Feature icon={
                <FontAwesomeIcon icon={faBug} style={{
                  fontSize: "3.5em"
                }}/>
              }
              label={"Debug HTTP requests directly in VS Code via workbenches and collections"}/>

            <Feature icon={
                <FontAwesomeIcon icon={faCode} style={{
                  fontSize: "3.5em"
                }}/>
              }
              label={"Use custom inline scripts when executing requests or parsing environment variables"}/>

            <Feature icon={
                <FontAwesomeIcon icon={faServer} style={{
                  fontSize: "3.5em"
                }}/>
              }
              label={"Switch between environments and use .env files within requests"}/>
          </div>
        </div>
      </section>
    </main>
  );
}

type FeatureProps = {
  icon: ReactNode;
  label: ReactNode;
}

function Feature({ icon, label }: FeatureProps) {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      gap: "0.5em",
      alignItems: "center",
      flex: "1 1 0"
    }}>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "5em"
      }}>
        {icon}
      </div>

      {label}
    </div>
  );
}