import './../App.css';
import "fontsource-inter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

export default function Documentation() {
  return (
    <main style={{
      padding: "2em 0",

      display: "flex",
      justifyContent: "center"
    }}>
      <div className="container">
        <h1>Documentation</h1>
        
        <p>Integration Workbench is a free and open-source API integration testing extension for VS Code with the ability to group requests, run scripts to prepare a request, use environments, all in a familar VS Code environment.</p>
      
        <h2 id="getting-started">Getting started</h2>

        <ol>
          <li>
            Install the extension from the{" "}
            <a href="https://marketplace.visualstudio.com/items?itemName=norasoderlund.integrationworkbench&ssr=false#overview" target="_blank" rel="noreferrer" style={{
              display: "inline-flex",
              flexDirection: "row",
              gap: "0.3em"
            }}>
              Marketplace
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
          </li>
        </ol>

        <p><i>Still working on this! See the VS Code walkthroughs for help in setting up your first request.</i></p>
      </div>
    </main>
  );
}
