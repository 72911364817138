import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  return (
    <header style={{
      display: "flex",
      justifyContent: "center",

      fontSize: "1.1em",

      position: "sticky"
    }}>
      <div className="container" style={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5em",
        padding: "1em 0.5em",
        alignItems: "center"
      }}>
        {/*<img src={icon} style={{
          height: "2.5em"
        }} alt="Integration Workbench icon"/>*/}
        
        <a href="/"><b>Integration Workbench</b></a>
        
        <div style={{
          marginLeft: "auto",

          display: "flex",
          flexDirection: "row",
          gap: "1em",

          color: "rgb(200, 200, 200)"
        }}>
          <a href="/documentation">Documentation</a>

          <a href="https://github.com/nora-soderlund/IntegrationWorkbench" target="_blank" rel="noreferrer" style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5em"
          }}>
            GitHub
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
        </div>
      </div>
    </header>
  );
}