export default function Preview() {
  return (
    <section style={{
      backgroundImage: "radial-gradient(#4b4b4b 1px, transparent 0)",
      backgroundSize: "24px 24px",
      backgroundPosition: "-6px -6px",

      height: "60vh",

      justifyContent: "center",

      display: "flex",
      flexDirection: "row",

      overflow: "hidden"
    }}>
      <div style={{
        width: "40%",

        display: "flex",
        flexDirection: "column",

        alignItems: "flex-end",
        justifyContent: "center"
      }}>
        <div style={{
          maxWidth: "35em"
        }}>
          <h1>Integration Workbench</h1>

          <p>Integration Workbench is a free and open-source API integration testing extension for VS Code with the ability to group requests, run scripts to prepare a request, use environments, all in a familar VS Code environment.</p>
        </div>
      </div>

      <div style={{
        width: "60%",
        height: "90%",

        marginTop: "auto",
        marginLeft: "auto",
        marginBottom: "-2px",

        aspectRatio: 1.72745,

        display: "flex",
        justifyContent: "center"
      }}>
        <div style={{
          height: "100%",
        }}>
          <video muted autoPlay style={{
            height: "100%",

            boxShadow: "rgba(0, 0, 0, 0.5) 0px 10px 30px",

            aspectRatio: 1.72745,

            borderTopLeftRadius: ".5em",
            borderTopRightRadius: ".5em",
            overflow: "hidden",

            background: "#2B2C31"
          }}>
            <source src="./resources/preview.mp4" type="video/mp4"/>
            
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
}