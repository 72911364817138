import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer() {
  return (
    <footer style={{
      background: "rgb(61, 61, 65)",

      display: "flex",
      justifyContent: "center",

      padding: "2em 0"
    }}>
      <div className="container" style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
      }}>
      <div style={{
        flex: "1 1 0"
      }}>
        <b>Author</b>

        <ul style={{
          display: "grid",
          gap: ".75em",
          listStyle: "none",
          padding: 0
        }}>
          <li>
            <a href="https://nora-soderlund.com" target="_blank" rel="noreferrer" style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5em",
            alignItems: "center"
          }}>
              Website

              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
          </li>

          <li>
            <a href="http://linkedin.com/in/nora-soderlund/" target="_blank" rel="noreferrer" style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5em",
            alignItems: "center"
          }}>
              LinkedIn

              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
          </li>
        </ul>
      </div>

        <div style={{
          flex: "1 1 0"
        }}>
          <b>Links</b>

          <ul style={{
            display: "grid",
            gap: ".75em",
            listStyle: "none",
            padding: 0
          }}>
            <li>
              <a href="/documentation#getting-started" style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5em",
              alignItems: "center"
            }}>
                Getting started
              </a>
            </li>

            <li>
              <a href="https://marketplace.visualstudio.com/items?itemName=norasoderlund.integrationworkbench&ssr=false#overview" target="_blank" rel="noreferrer" style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5em",
              alignItems: "center"
            }}>
                Marketplace

                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            </li>
          </ul>
        </div>

        <div style={{
          flex: "1 1 0"
        }}>
          <b>Contributions</b>

          <ul style={{
            display: "grid",
            gap: ".75em",
            listStyle: "none",
            padding: 0
          }}>
            <li>
              <a href="https://github.com/nora-soderlund/IntegrationWorkbench/issues" target="_blank" rel="noreferrer" style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5em",
              alignItems: "center"
            }}>
                Issues

                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            </li>
          
            <li>
              <a href="https://github.com/nora-soderlund/IntegrationWorkbench" target="_blank" rel="noreferrer" style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5em",
              alignItems: "center"
            }}>
                Repository

                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            </li>

            <li>
              <a href="https://github.com/nora-soderlund/IntegrationWorkbench/pulls" target="_blank" rel="noreferrer" style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5em",
              alignItems: "center"
            }}>
                Pull requests

                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
